import { type FC } from 'react';
import { format } from 'date-fns';
import { CalendarIcon, XIcon } from 'lucide-react';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { DateRange } from 'react-day-picker';

type RangeDatePickerProps = {
  id?: string;
  selected?: DateRange | null;
  onSelect?: (selected?: DateRange | null) => void;
  placeholder?: string;
  className?: string;
};

// if min < 0, then we will have from and to dates on first click
// https://github.com/gpbl/react-day-picker/blob/6b0abf9710d7d822de366924ffe8591d2d6fc285/src/utils/addToRange.ts#L30
export const RangeDatePicker: FC<RangeDatePickerProps> = ({
  id,
  selected,
  onSelect,
  className,
  placeholder = 'Pick a date range',
}) => {
  const value = selected === null ? undefined : selected;
  const onValue = (value?: DateRange) => {
    if (value === undefined) {
      onSelect?.(null);
    } else {
      onSelect?.(value);
    }
  };

  const clear = () => onValue(undefined);

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id={id}
            variant={'outline'}
            className={cn(
              'gap-2 min-w-[255px] justify-start text-left font-normal relative',
              !selected && 'text-muted-foreground',
            )}
          >
            <CalendarIcon className="size-4" />

            <span>
              {selected?.from ? (
                selected.to ? (
                  <>
                    {format(selected.from, 'LLL dd, y')} - {format(selected.to, 'LLL dd, y')}
                  </>
                ) : (
                  format(selected.from, 'LLL dd, y')
                )
              ) : (
                placeholder
              )}
            </span>

            {selected?.from && (
              <XIcon
                role="button"
                className="absolute right-0 top-2/4 size-4 -translate-x-2/4 -translate-y-2/4 text-muted-foreground hover:text-primary"
                onClick={(e) => {
                  clear();
                  e.preventDefault();
                }}
              />
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar autoFocus mode="range" selected={value} onSelect={onValue} min={1} numberOfMonths={2} />
        </PopoverContent>
      </Popover>
    </div>
  );
};
