import { createFileRoute } from '@tanstack/react-router';
import { Sidebar } from '@/components/sidebar';
import { CallStatistics } from '@/feature/call-statistics/call-statistics';
import { CallLogs } from '@/feature/call-logs/call-logs';
import { beforeLoad } from '@/utils/routes.utils.ts';
import { ActiveCalls } from '@/feature/active-calls/active-calls';
import { CallFilters } from '@/feature/call-filters/call-filters';
import { useChatsUpdateSubscription } from '@/hooks/use-chats-update';

function Index() {
  useChatsUpdateSubscription();

  return (
    <div className="flex min-h-screen w-full flex-col bg-muted/40">
      <Sidebar />

      <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14">
        <main className="gap-4 p-4 sm:px-6 sm:py-0">
          <div>
            <CallFilters />
            <CallStatistics />
            <ActiveCalls />
            <CallLogs />
          </div>
        </main>
      </div>
    </div>
  );
}

export const Route = createFileRoute('/')({
  component: Index,
  beforeLoad,
});
