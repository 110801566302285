import { DependencyList, useEffect } from 'react';
import { useCallFilters } from '@/feature/call-filters/call-filters';
import { Chat } from '@/feature/chat/chat.types';
import { trpc } from '@/trpc';

const Type = {
  Data: 'data',
};

const eventEmitter = new EventTarget();

export type ChatsUpdate = Chat[];

const triggerUpdate = (update: ChatsUpdate) => {
  eventEmitter.dispatchEvent(new CustomEvent<ChatsUpdate>(Type.Data, { detail: update }));
};

export const useChatsUpdate = (onUpdates: (update: ChatsUpdate) => void, deps?: DependencyList) => {
  useEffect(() => {
    const abortController = new AbortController();

    eventEmitter.addEventListener(
      Type.Data,
      (event) => {
        onUpdates((event as CustomEvent<ChatsUpdate>).detail);
      },
      { signal: abortController.signal },
    );

    return () => abortController.abort();
  }, deps);
};

export const useChatsUpdateSubscription = () => {
  const filters = useCallFilters((state) => state.filters);

  trpc.onChatsUpdate.useSubscription(filters, {
    onData: (update) => {
      if (!update.length) {
        return;
      }

      triggerUpdate(update);
    },
  });
};
