import { createFileRoute } from '@tanstack/react-router';
import { trpc } from '@/trpc.ts';
import { Sidebar } from '@/components/sidebar';
import { beforeLoad } from '@/utils/routes.utils.ts';
import { ChatMessages } from '@/feature/chat/chat-messages.tsx';
import { ChatDetails } from '@/feature/chat/chat-details.tsx';
import { getQueryKey } from '@trpc/react-query';
import { useQueryClient } from '@tanstack/react-query';

export const Route = createFileRoute('/chats/$chatId')({
  component: Chat,
  beforeLoad,
});

function Chat() {
  const { chatId } = Route.useParams();

  const queryClient = useQueryClient();
  const { data, isLoading, error } = trpc.getChat.useQuery(chatId);
  const queryKey = getQueryKey(trpc.getChat, chatId, 'query');

  trpc.onChatUpdate.useSubscription(chatId, {
    onData: (chat) => {
      queryClient.setQueryData(queryKey, chat);
    },
  });

  if (error) {
    return <div className="p-2">Error {error.message}</div>;
  }

  if (isLoading) {
    return <div className="p-2">Loading...</div>;
  }

  if (!data) {
    return <div className="p-2">Chat not found</div>;
  }

  return (
    <div className="flex min-h-screen w-full flex-col bg-muted/40">
      <Sidebar />

      <div className="sm:gap-4 sm:py-4 sm:pl-14">
        <div className="grid grid-cols-2 gap-8 p-6">
          <div>
            <ChatDetails chat={data} />
          </div>
          <div>
            <ChatMessages messages={data.messages} />
          </div>
        </div>
      </div>
    </div>
  );
}
