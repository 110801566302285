import { FC } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { RangeDatePicker } from '@/components/ui/date-picker';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { RouterInput } from '@/trpc';
import { startOfDay, subDays } from 'date-fns';
import { MultiSelect } from '@/components/ui/multi-select';

export enum TransferStatus {
  NoTransfer = 'NO_TRANSFER',
  NoAnswer = 'NO_ANSWER',
  Answered = 'ANSWERED',
}

const TRANSFER_STATUS_OPTIONS = Object.values(TransferStatus).map((value) => ({ value, label: value }));

const formSchema = z.object({
  dateRange: z.object({ from: z.date(), to: z.date().optional() }).nullable().optional(),

  // to prevent '' in output
  from: z
    .string()
    .trim()
    .optional()
    .transform((value) => value || undefined),
  transferTo: z
    .string()
    .trim()
    .optional()
    .transform((value) => value || undefined),

  transferStatusOptions: z.array(z.object({ value: z.nativeEnum(TransferStatus), label: z.string() })).optional(),
});

type FormParameters = z.infer<typeof formSchema>;

type CallFiltersProps = {
  className?: string;
};

type CallFilters = Omit<RouterInput['getChats'], 'cursor' | 'limit'>;

const DEFAULT_DATE_FROM_DAYS_AGO = 7;

export const useCallFilters = create(
  combine(
    { filters: { dateFrom: startOfDay(subDays(new Date(), DEFAULT_DATE_FROM_DAYS_AGO)) } as CallFilters },
    (set) => ({
      setFilters: (filters: CallFilters) => set({ filters }),
    }),
  ),
);

const toFormValues = ({ dateFrom, dateTo, transferStatuses, ...rest }: CallFilters): FormParameters => {
  const dateRange = dateFrom ? { from: dateFrom, to: dateTo } : undefined;
  const transferStatusOptions = transferStatuses?.map((value) => ({ value, label: value }));

  return { dateRange, transferStatusOptions, ...rest };
};

export const CallFilters: FC<CallFiltersProps> = ({ className }) => {
  const setFilters = useCallFilters((state) => state.setFilters);
  const filters = useCallFilters((state) => state.filters);

  const form = useForm<FormParameters>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      // to prevent https://stackoverflow.com/questions/68112196/uncontrolled-input-to-controlled-input-warning-in-react-hook-form-and-material-u
      from: '',
      transferTo: '',

      ...toFormValues(filters),
    },
  });

  const onSubmit = ({ dateRange, transferStatusOptions, ...rest }: FormParameters) => {
    const dateFrom = dateRange?.from;
    const dateTo = dateRange?.to;
    const transferStatuses = transferStatusOptions?.length
      ? transferStatusOptions?.map(({ value }) => value)
      : undefined;

    setFilters({ dateFrom, dateTo, transferStatuses, ...rest });
  };

  return (
    <>
      <h3 className="mb-2 mt-8 scroll-m-20 text-2xl font-semibold tracking-tight">Filters</h3>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={cn('md:flex gap-2', className)}>
          <FormField
            control={form.control}
            name="dateRange"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Date range</FormLabel>
                  <FormControl>
                    <RangeDatePicker selected={field.value} onSelect={field.onChange} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="from"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>From</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="transferTo"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Transfer to</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="transferStatusOptions"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Transfer statuses</FormLabel>
                  <FormControl>
                    <MultiSelect options={TRANSFER_STATUS_OPTIONS} value={field.value} onChange={field.onChange} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <div className="ml-auto mt-2 flex items-end justify-end md:mt-0">
            <Button type="submit">Apply</Button>
          </div>
        </form>
      </Form>
    </>
  );
};
