import { trpc } from '@/trpc.ts';
import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from '@/components/ui/data-table';
import { AppRouter } from '@receptionist/api';
import { inferRouterOutputs } from '@trpc/server';
import { Link } from '@tanstack/react-router';
import { toFormattedDate } from '@/lib/date-utils.ts';
import { ChatRecording } from '../chat-recording/chat-recording';
import { useCallFilters } from '../call-filters/call-filters';
import { createStateContainer } from '@/components/data-container';
import { ChatsUpdate, useChatsUpdate } from '@/hooks/use-chats-update';
import { useQueryClient } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';

type RouterOutput = inferRouterOutputs<AppRouter>;

type ChatType = RouterOutput['getChats']['data'][number];

const columns: ColumnDef<ChatType>[] = [
  {
    accessorKey: 'id',
    header: 'Chat log',
    size: 100,
    cell: ({ row }) => (
      <Link to="/chats/$chatId" params={{ chatId: row.getValue('id') as string }}>
        Chat log
      </Link>
    ),
  },
  {
    accessorKey: 'createdAt',
    header: 'Date Time',
    size: 150,
    cell: ({ row }) => {
      const createdAt = row.getValue('createdAt') as string;
      const formattedDate = toFormattedDate(createdAt);
      const [date, time] = formattedDate.split(',');

      return (
        <div>
          <div>{date}</div>
          <div>{time}</div>
        </div>
      );
    },
  },
  {
    accessorKey: 'fromName',
    header: 'Caller Name',
    size: 150,
    cell: ({ row }) => <div>{row.getValue('fromName')}</div>,
  },
  {
    accessorKey: 'from',
    header: 'From',
    size: 120,
    cell: ({ row }) => <div>{row.getValue('from')}</div>,
  },
  {
    accessorKey: 'transferStatus',
    header: 'Transfer Status',
    size: 200,
    cell: ({ row }) => <div>{row.getValue('transferStatus')}</div>,
  },
  {
    accessorKey: 'summary',
    header: 'Summary',
    size: 700,
    cell: ({ row }) => <div>{row.getValue('summary')}</div>,
  },
  {
    header: 'Recording',
    size: 320,
    cell: ({ row }) => {
      const id = row.getValue('id') as string;

      return <ChatRecording id={id} />;
    },
  },
];

const Container = createStateContainer('Call logs');

export type OldData = { pages: RouterOutput['getChats'][]; pageParams: unknown[] };

const PAGE_SIZE = 50;

export const mergeChats = (update: ChatsUpdate, oldData: OldData) => {
  if (!oldData?.pages?.length) {
    return {
      pages: [
        {
          data: update,
          nextCursor: null,
          total: update.length,
        },
      ],
      pageParams: [null],
    };
  }

  const existingChats = oldData.pages.flatMap((page) => page.data);
  const chatMap = new Map(existingChats.map((chat) => [chat.id, chat]));

  let created = 0;

  for (const chat of update) {
    if (!chatMap.has(chat.id)) {
      created++;
    }

    chatMap.set(chat.id, chat);
  }

  const sortedChats = Array.from(chatMap.values()).sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  const pageSize = PAGE_SIZE;
  const total = oldData.pages[0].total + created;

  const newPages = oldData.pages.map((_, pageIndex) => {
    const start = pageIndex * pageSize;
    const end = start + pageSize;

    return {
      data: sortedChats.slice(start, end),
      nextCursor: sortedChats[end + 1]?.createdAt,
      total,
    };
  });

  return {
    ...oldData,
    pages: newPages,
  };
};

export const CallLogs = () => {
  const { filters } = useCallFilters();

  const filter = { limit: PAGE_SIZE, ...filters };

  const queryClient = useQueryClient();
  const queryKey = getQueryKey(trpc.getChats, filter, 'infinite');

  const { data, isLoading, error, isFetching, fetchNextPage, hasNextPage } = trpc.getChats.useInfiniteQuery(filter, {
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });

  useChatsUpdate(
    (chatsUpdate) => {
      queryClient.setQueryData(queryKey, (oldData: OldData) => {
        return mergeChats(chatsUpdate, oldData);
      });
    },
    [queryKey, queryClient],
  );

  const flatData = data?.pages.flatMap((page) => page.data) ?? [];
  const totalCount = data?.pages[0]?.total ?? 0;

  if (error) {
    return <Container.Error />;
  }

  if (isLoading) {
    return <Container.Loading />;
  }

  if (!flatData) {
    return <Container.NoData />;
  }

  return (
    <Container>
      <DataTable
        data={flatData}
        columns={columns}
        isFetchingNextPage={isFetching}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        totalCount={totalCount}
      />
    </Container>
  );
};
